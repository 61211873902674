<!--
 * @Author: your name
 * @Date: 2020-10-14 16:18:01
 * @LastEditTime: 2021-07-15 15:05:19
 * @LastEditors: yangliao
 * @Description: 用水统计
 * @FilePath: /netHallOfficialAccounts/src/views/WaterStatistics/index.vue
-->
<template>
  <div class="waterStatistics-page">
    <div v-if="isBind">
      <div :class="selectedFlag ? 'waterStatistics-select' : 'waterStatistics-selected'">
        <van-dropdown-menu active-color="#1677FF" :close-on-click-overlay="false">
          <van-dropdown-item :title="billYear" ref="item">
            <drop-down-selection
              :currentValue="currentIndex"
              :dataValArr="yearList"
              @getSelectValue="getSelectValueInfo"
            />
          </van-dropdown-item>
        </van-dropdown-menu>
      </div>
      <div class="waterStatistics-txtInfo">
        <ul>
          <!-- isClickMailBtn -->
          <li
            v-for="(item, index) in dataInfo"
            :key="index"
            :class="item.isChecked ? 'invoice-li currentLi' : 'invoice-li'"
            @click="gotoOne(item)"
          >
            <div class="first-li">
              <div class="wuser-name">
                <div>{{ item.wuserName }}</div>
                <div>户号: {{ item.wuserNo }}</div>
              </div>
              <div class="volume-total">{{ item.waterVolumeTotal }}吨</div>
            </div>
            <div class="center-li">
              <p>
                <em v-if="item.labelName && item.labelName.length > 0">{{ item.labelName }}</em>
                {{ item.wuserAddress }}
              </p>
            </div>
          </li>
        </ul>
      </div>

      <div class="lineChart">
        <span>用水量:吨</span>
        <div class="chartDiv">
          <line-chart chartId="equip-chart" :option="chartOption" />
        </div>
      </div>

      <div class="lineChart">
        <span>用水量:吨</span>
        <div class="pieDiv">
          <pie-chart ref="pieChartItem2" :chartId="'flowPie'" :option="pieOption" />
        </div>
      </div>
    </div>
    <no-Data v-else />
  </div>
</template>

<script>
import LineChart from '@/components/chart/LineChart';
import PieChart from '@/components/chart/PieChart';
import DropDownSelection from '@/components/dropDownSelection';

import { selectWaterStatistics } from '../../api/home';
import NoData from '@/components/noData.vue';
import store from '@/store';
import moment from 'moment';

export default {
  name: 'WaterStatistics',
  components: {
    LineChart,
    PieChart,
    DropDownSelection,
    NoData,
  },
  data() {
    return {
      billYear: moment().format('YYYY') + '年',
      yearList: [],
      currentMounth: '',
      dataInfo: [],
      isBind: store.getters.homeInfo.isBind,
      currentIndex: 0,
      selectedFlag: true, // 反馈类型筛选的颜色控制
      chartOption: {
        grid: [{ top: '5%', left: '3%', right: '3%', bottom: '11%', containLabel: true }],
        tooltip: {
          trigger: 'axis',
          backgroundColor: '#ffffff',
          extraCssText: 'box-shadow: 0 2px 8px 0 #D2E7FF;',
          formatter: function(params) {
            let res = '';
            for (let i = 0; i < params.length; i++) {
              res += params[i].marker + ' ' + params[i].value + '</br>';
            }
            return params[0].name + '</br>' + res;
          },
          axisPointer: {
            type: 'cross',
            animation: false,
            label: { show: false },
          },
          textStyle: { color: 'rgba(146,146,146)', fontSize: 12 },
        },
        color: [
          '#F5A623',
          '#7ED321',
          '#4A90E2',
          '#8A86F6',
          '#488BFF',
          '#26CEBA',
          '#FFC069',
          '#6D7EE4',
          '#FF9C6E',
        ],
        legend: {
          type: 'scroll',
          data: [],
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            axisLine: {
              lineStyle: { color: 'rgba(146,146,146)' },
            },
            axisLabel: { rotate: 45 },
            data: [],
          },
        ],
        yAxis: [
          {
            name: '',
            type: 'value',
            minInterval: 0.2,
            axisLine: {
              show: false,
              lineStyle: { color: 'rgba(146,146,146)' },
            },
            splitLine: {
              lineStyle: { color: ['rgba(135,150,176,0.2)'], type: 'dashed' },
            },
            axisTick: {
              show: false, // 刻度线是否显示
            },
          },
        ],
        series: [],
      },
      pieOption: {
        title: {
          zlevel: 0,
          text: ['{name|总量}', '{value|￥' + 0 + '}'].join('\n'),
          top: 'center',
          left: '46%', // '145',
          textAlign: 'center',
          textStyle: {
            rich: {
              name: { color: '#909399', lineHeight: 20 },
              value: { color: '#303133', fontSize: 16, fontWeight: 'bold', lineHeight: 16 },
            },
          },
        },
        grid: [{ top: '0%', left: '3%', right: '3%', bottom: '11%', containLabel: true }],
        tooltip: {
          backgroundColor: '#ffffff',
          extraCssText: 'box-shadow: 0 2px 8px 0 #D2E7FF;',
          axisPointer: {
            type: 'cross',
            animation: false,
            label: { show: false },
          },
          textStyle: { color: 'rgba(146,146,146)' },
          position: [50, 50],
          formatter: (param) => {
            return `${param.marker}${param.name} ${param.value}%`;
          },
        },
        legend: {
          type: 'scroll',
          icon: 'roundRect',
          data: [],
        },
        color: ['#F5A623 ', '#4A90E2', '#BAE788'],
        series: [
          {
            type: 'pie',
            radius: ['30%', '50%'],
            center: ['46%', '50%'],
            roseType: false,
            avoidLabelOverlap: true,
            label: {
              normal: {
                show: true,
                lineHeight: 16,
                formatter: (params) => {
                  return `${params.name.split('-')[0]}\n${params.data.value}%`;
                },
              },
              emphasis: { show: true },
            },
            labelLine: {
              normal: { show: true, smooth: 0, length: 10, length2: 10 },
            },
            data: [],
          },
        ],
      },
    };
  },
  created() {
    this.getYear();
  },
  mounted() {
    // 页面数据
    if (store.getters.homeInfo.isBind) {
      this.initFn();
    }
  },
  methods: {
    async initFn() {
      let billYear = this.billYear.substring(0, this.billYear.lastIndexOf('年'));
      const arg = {
        billYear: billYear,
      };
      const { status, resultData } = await selectWaterStatistics(arg);
      if (status === 'complete') {
        this.dataInfo = resultData;

        // 折线图数据：
        resultData &&
          resultData.map((item, index) => {
            // 图例：legend
            this.chartOption.legend.data.push(`${item.labelName}-${item.wuserNo}`);
            this.pieOption.legend.data.push(`${item.labelName}-${item.wuserNo}`);
            // 数据
            item.listData = [];
            item.xAxisData = [];
            item.waterVolumeList.map((list, listIndex) => {
              for (let i in list) {
                item.listData.push(list[i]);
                item.xAxisData.push(i + '月');
              }
            });
            this.chartOption.series.push({
              name: `${item.labelName}-${item.wuserNo}`,
              type: 'line',
              // stack: '总量',
              symbolSize: 4,
              areaStyle: {
                color: {
                  type: 'linear',
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    { offset: 0, color: this.chartOption.color[index] },
                    { offset: 1, color: 'rgba(255,255,255,0)' },
                  ],
                  global: false, // 缺省为 false
                },
              },
              data: item.listData,
            });
          });
        this.chartOption.xAxis[0].data = resultData[0].xAxisData;

        // 饼图数据
        let total = 0;
        resultData &&
          resultData.map((item, index) => {
            let obj = {};
            obj.name = `${item.labelName}-${item.wuserNo}`;
            obj.value = item.waterVolumeTotalPercentage;
            this.pieOption.series[0].data.push(obj);
            // 合计
            total = item.allAcountwaterVolumeTotal;
          });
        this.pieOption.title.text = ['{name|总量}', '{value|' + total + '}'].join('\n');
      }
    },
    // 选择年份
    getSelectValueInfo(value, index) {
      this.billYear = value;
      this.$refs.item.toggle();
      // 请空后重新加载
      this.selectedFlag = false; // 反馈类型筛选的颜色控制
      this.currentIndex = index;
      this.dataInfo = [];
      this.chartOption.legend.data = [];
      this.pieOption.legend.data = [];
      this.chartOption.series = [];
      this.pieOption.series[0].data = [];
      this.initFn();
    },
    // 选择年份 - 获取年份(12个月份)
    getYear() {
      const date = new Date();
      const year = date.getFullYear();

      for (let i = year - 11; i <= year; i++) {
        this.yearList.push({
          name: i + '年',
        });
      }
      this.yearList.reverse();
    },
    gotoOne(data) {
      const labelName = data.labelName;
      const wuserNo = data.wuserNo;
      const custWaterUserRelId = data.custWaterUserRelId;
      const isWaterUser = data.isWaterUser;
      this.$router.push({
        name: 'WaterAccount',
        path: '/WaterAccount',
        query: {
          labelName,
          wuserNo,
          custWaterUserRelId,
          isWaterUser,
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.waterStatistics-page {
  .waterStatistics-select {
    display: flex;
    justify-content: space-between;
    padding: 16px 16px 10px 16px;
    font-size: 14px;
    background: #ffffff;
    /deep/.van-dropdown-menu__title {
      padding: 0px;
      width: 75px;
      text-align: center;
      color: rgba(0, 0, 0, 0.65);
    }
    /deep/.van-dropdown-menu__bar {
      height: auto;
      background: transparent;
      box-shadow: none;
    }
    /deep/.van-dropdown-menu__title::after {
      border-color: transparent transparent rgba(0, 0, 0, 0.65) rgba(0, 0, 0, 0.65);
    }
    // /deep/.van-dropdown-menu__title::after {
    //     border-color: transparent transparent #555555 #555555;
    // }
    /deep/.van-dropdown-menu__item {
      margin-right: 10px;
    }
  }
  // 选中的样式
  .waterStatistics-selected {
    display: flex;
    justify-content: space-between;
    padding: 16px 16px 10px 16px;
    font-size: 14px;
    background: #ffffff;
    /deep/.van-dropdown-menu__bar {
      height: auto;
      background: transparent;
      box-shadow: none;
    }
    /deep/.van-dropdown-menu__title {
      width: 75px;
      color: #1677ff;
    }
    /deep/.van-dropdown-menu__title::after {
      border-color: transparent transparent #1677ff #1677ff;
    }
  }
  .waterStatistics-txtInfo {
    margin: 0px 16px;
    background: #f3f3f3;
    margin-top: 10px;
    border-radius: 8px;
    ul {
      li {
        padding-bottom: 16px;
        position: relative;
      }
      li::after {
        position: absolute;
        box-sizing: border-box;
        content: ' ';
        pointer-events: none;
        right: 16px;
        bottom: 0;
        left: 16px;
        border-bottom: 1px solid #ebedf0;
        -webkit-transform: scaleY(0.5);
        transform: scaleY(0.5);
      }
      li:last-child {
        border-bottom: 0px solid transparent;
      }
      .invoice-li {
        background-color: #ffffff;
        position: relative; // 选中样式
        overflow: hidden;
        border: 1px solid transparent;

        .first-li {
          display: flex;
          justify-content: space-between;
          font-family: PingFangSC-Medium;
          padding: 16px 16px 0 16px;
          font-size: 16px;
          color: rgba(0, 0, 0, 0.85);
          letter-spacing: 0;
          line-height: 20px;
          font-weight: 600;
          .wuser-name {
            display: flex;
            flex-wrap: wrap;
            padding-right: 8px;

            > div {
              margin-bottom: 8px;
            }

            > div:first-child {
              padding-right: 8px;
            }
          }
          .volume-total {
            white-space: nowrap;
          }
          // span {
          //   font-family: PingFangSC-Medium;
          //   font-size: 16px;
          //   color: rgba(0, 0, 0, 0.85);
          //   letter-spacing: 0;
          //   line-height: 20px;
          //   font-weight: 600;
          // }
        }
        .center-li {
          margin-left: 16px;
          margin-right: 16px;
          em {
            font-size: 12px;
            padding: 1px 5px;
            font-style: normal;
            font-family: PingFangSC-Regular;
            color: #637fb7;
            letter-spacing: 0;
            line-height: 16px;
            background: #ecf1fe;
            border-radius: 2px;
          }
          p {
            margin: 0px;
            font-size: 14px;
            font-family: PingFangSC-Regular;
            color: rgba(0, 0, 0, 0.65);
            letter-spacing: 0;
            line-height: 22px;
          }
        }
        .last-li {
          display: flex;
          justify-content: space-between;
          margin-left: 16px;
          margin-right: 16px;
          padding-bottom: 11px;
          span {
            font-size: 12px;
            line-height: 32px;
            font-family: PingFangSC-Regular;
            color: rgba(0, 0, 0, 0.45);
            letter-spacing: 0;
          }
          .van-button {
            border-radius: 8px;
            width: 64px;
            span {
              font-family: PingFangSC-Regular;
              font-size: 12px;
              color: #4285f4;
              letter-spacing: 0;
              text-align: center;
              line-height: 24px;
            }
          }
          .van-button--default {
            border: 1px solid #4285f4;
          }
          .van-button--small {
            padding: 0px;
          }
        }
      }
      // 选中样式
      .currentLi {
        border: 1px solid #1c74fa;
      }
      .triangle {
        width: 50px;
        height: 50px;
        background: #1677ff;
        position: absolute;
        top: -25px;
        right: -28px;
        transform: rotate(45deg);
        span {
          position: absolute;
          bottom: -4px;
          display: block;
          width: 50px;
          text-align: center;
          color: #ffffff;
          transform: rotate(-25deg);
        }
      }
    }
  }
  .lineChart {
    border-radius: 8px;
    overflow: hidden;
    padding: 16px;
    margin: 16px;
    background: #ffffff;
    span {
      font-weight: 700;
      font-size: 14px;
    }
    .chartDiv {
      height: 300px;
    }
    .pieDiv {
      height: 260px;
    }
  }
}
</style>
